<template>
  <div>
    <v-radio-group
      v-model="userRole"
    >
      <v-radio
        v-for="role in roles"
        :key="role.id_role"
        :label="$t(role.role_name)"
        :value="role.id_role"
        class="mb-4"
      ></v-radio>
    </v-radio-group>

    <v-row v-if="isRoleChanged">
      <v-col>
        <v-btn
          block
          color="primary"
          @click="handleUpdateUserRole"
        >
          {{ $t('Submit') }}
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          outlined
          block
          @click="handleResetRole"
        >
          {{ $t('Reset') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, inject, onMounted } from '@vue/composition-api'
import AccountService from '@/services/AccountService'
import { useRouter } from '@core/utils'
import store from '@/store'

export default {
  setup() {
    const { router } = useRouter()
    const userId = router.currentRoute.params.id
    const isSubmitting = ref(false)
    const userData = inject('userData', {})
    const getUserData = inject('getUserData', async () => {})
    const userRole = ref(userData.value.role)
    const roles = ref([])

    const isRoleChanged = computed(() => !!userRole.value && userRole.value !== userData.value.role)

    const handleGetRoles = async () => {
      const resp = await AccountService.getRoles()
      const { data } = resp
      roles.value = data.data || []
    }

    onMounted(() => {
      handleGetRoles()
    })

    const handleResetRole = () => {
      userRole.value = userData.value.role
    }

    const handleUpdateUserRole = async () => {
      try {
        isSubmitting.value = true
        await AccountService.assignRoleToUser(userId, {
          role: userRole.value,
        })
        store.commit('snackbar/show', {
          message: 'Update role successfully',
          type: 'success',
        })
        await getUserData()
      } catch {
      } finally {
        isSubmitting.value = false
      }
    }

    return {
      roles,
      userRole,
      isRoleChanged,

      handleResetRole,
      handleUpdateUserRole,
    }
  },
}
</script>
